import { AgdirSelectComponent, AgdirSelectOption } from '@agdir/agdir-forms';
import { BaseComponent } from '@agdir/core/angular';
import { LOCAL_STORAGE_USER_LANGUAGE_KEY, SUPPORTED_LANGUAGE, SUPPORTED_LANGUAGES } from '@agdir/i18n/angular';
import { AuthService, ProfileService } from '@agdir/services';
import { AgdirButtonColor, ButtonComponent, ButtonSizeType } from '@agdir/ui/button';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, Input, model, OnInit, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { first, firstValueFrom, takeUntil } from 'rxjs';
import { SUPPORTED_LANGUAGES_SHORT } from '../../i18n/angular/src/SUPPORTED_LANGUAGES_SHORT';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { PageNavigationItem } from '@agdir/ui/menu';
import { DropdownMenuNavigationComponent } from '../../ui/menu/dropdown-menu-navigation.component';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-languages',
	template: `
		@if (displayAs === 'flags') {
			<agdir-button
				icon="g_translate"
				[label]="currentLanguageName()"
				[color]="color()"
				[size]="size()"
				nz-popover
				nzPopoverTrigger="hover"
				nzPopoverOverlayClassName="agdir-ant-popover-inner-content-zero-padding"
				(click)="visible.set(!visible())"
				[nzPopoverContent]="contentTemplate"
				nzPopoverPlacement="bottom"
			/>
			<ng-template #contentTemplate>
				<agdir-dropdown-menu-navigation [items]="availableFlags()">
					<div style="font-size:20px;" class="font-bold pb-1 border-b border-b-gray-950/[.20]" before>
						{{ 'general.languages.selectionLabel' | transloco }}
					</div>
				</agdir-dropdown-menu-navigation>
			</ng-template>
		} @else if (displayAs === 'dropdown') {
			@for (lang of availableLangs(); track $index) {
				<agdir-button [color]="control.value === lang[0] ? 'secondary' : 'ghost'" (click)="setActiveLang(lang[0])" [label]="lang[1]" />
			}
		} @else {
			<agdir-select class="hidden md:flex" [options]="availableLangsOption" [selectControl]="control" (changed)="setActiveLang($event.value)" />
			<agdir-select class="md:hidden" [options]="availableLangsOptionShort" [selectControl]="control" (changed)="setActiveLang($event.value)" />
		}
	`,
	imports: [AgdirSelectComponent, ButtonComponent, NgOptimizedImage, NzPopoverDirective, TranslocoPipe, DropdownMenuNavigationComponent],
})
export class LanguagesComponent extends BaseComponent implements OnInit {
	currentLanguage = signal('');
	currentLanguageName = signal('');
	availableLangs = signal<[SUPPORTED_LANGUAGE, string][]>([...SUPPORTED_LANGUAGES.entries()]);
	availableFlags = computed<PageNavigationItem[]>(() =>
		this.availableLangs().map(([lang, name]) => ({
			image: `/assets/images/flags/${lang.toLocaleUpperCase()}.svg`,
			label: name,
			click: () => this.setActiveLang(lang),
			isActive: () => this.currentLanguage() === lang,
		})),
	);
	@Input() control = new FormControl<SUPPORTED_LANGUAGE>('', { nonNullable: true });
	availableLangsOption: AgdirSelectOption[] = [];
	availableLangsOptionShort: AgdirSelectOption[] = []; // TODO: Make it nice
	@Input() displayAs: 'dropdown' | 'select' | 'flags' = 'select';
	transloco = inject(TranslocoService);
	color = input<AgdirButtonColor>('secondary');
	size = input<ButtonSizeType>('default');

	private readonly profileService = inject(ProfileService);
	private readonly authService = inject(AuthService);
	protected readonly visible = model(false);

	async setActiveLang(lang: string) {
		this.currentLanguage.set(lang);
		window.localStorage.setItem(LOCAL_STORAGE_USER_LANGUAGE_KEY, lang);
		const me = await firstValueFrom(this.authService.getCurrentCustomerSession());
		if (me._id) {
			await this.profileService.updatePreference('language', lang);
		}
		// this.transloco.setActiveLang(lang);
		// setDefaultOptions({ locale: DATEFNS_LOCALES.get(lang) });
		window.location.reload();
	}
	async ngOnInit(): Promise<void> {
		this.availableLangsOption = this.availableLangs().map(([value, label]) => ({ value, label }));
		this.availableLangsOptionShort = this.availableLangs().map(([value, label]) => ({
			value,
			label: SUPPORTED_LANGUAGES_SHORT.get(value) || label,
		}));
		this.transloco.langChanges$.pipe(first(), takeUntil(this.destroyed$)).subscribe((activeLang) => {
			this.control.setValue(activeLang);
			this.currentLanguage.set(activeLang);
			this.currentLanguageName.set(this.availableLangs().find(([lang]) => lang === activeLang)?.[1] || '');
		});
	}
}
