import { Classifier, ClassifierService, WellKnownClassifierId } from '@agdir/classifiers';

import { AgdirIconComponent } from '@agdir/agdir-icons';
import { I18nService } from '@agdir/i18n/angular';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal, viewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzCascaderComponent, NzCascaderOption } from 'ng-zorro-antd/cascader';
import { NzFormControlComponent, NzFormItemComponent, NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { derivedAsync } from 'ngxtension/derived-async';
import { AddClassifierModalComponent } from '../../../../classifiers/src/lib/components/add-classifier-modal.component';

function findCascaderValuePath(tree: NzCascaderOption[] | undefined, value: Classifier): string[] {
	const find = (tree: NzCascaderOption[] | undefined, path: string[]): string[] => {
		if (!tree) {
			return [];
		}
		for (const node of tree) {
			if (node.value?.id === value?.id) {
				return [...path, node.value];
			}
			const found = find(node.children, [...path, node.value]);
			if (found.length) {
				return found;
			}
		}
		return [];
	};
	return find(tree, []);
}

@Component({
	standalone: true,
	selector: 'agdir-classifier-select',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<nz-form-item
			[class.flex-row]="horizontal() === true || horizontal() === 'true'"
			[class.items-center]="horizontal() === true || horizontal() === 'true'"
			[class.flex-col]="!horizontal() || horizontal() === 'false'"
			class="flex gap-2 justify-start"
		>
			@if (label() && size() == 'default') {
				<nz-form-label class="font-bold p-0" [class.leading-none]="horizontal() === true || horizontal() === 'true'">
					{{ label() | transloco }}
				</nz-form-label>
			}
			@if (label() && size() == 'large') {
				<div class="text-3xl md:text-4xl font-bold" [class.leading-none]="horizontal() === true || horizontal() === 'true'">
					{{ label() | transloco }}
				</div>
			}
			@if (description() && size() == 'large') {
				<div class="text-xl flex-1 font-light mb-5">{{ description() | transloco }}</div>
			}
			@if (classifiers(); as c) {
				<nz-cascader
					class="w-full"
					#cascader
					[ngModel]="selectedValue()"
					[nzExpandTrigger]="'hover'"
					[nzOptions]="c"
					[nzDisabled]="disabled()"
					[nzChangeOnSelect]="true"
					(nzSelectionChange)="pickedClassifier($event)"
					[nzLabelRender]="labelRenderer"
					[nzOptionRender]="optionRenderer"
				></nz-cascader>
			} @else {
				<agdir-spinner />
			}
			@if (!!newClassifierForParent()) {
				<app-add-classifier-modal
					[parent]="newClassifierForParent()"
					(cancel)="newClassifierForParent.set(null)"
					(classifierAdded)="addedClassifier($event)"
				/>
			}
			<ng-template #optionRenderer let-option let-index="index">
				<div class="flex items-center justify-between gap-3">
					@if (option.value === null) {
						<agdir-icon icon="add_circle" class="text-green-500" />
					}
					@if (option.value && !option.value?.companyId) {
						<img width="10" height="10" ngSrc="/assets/logo/logo-color.svg" alt="Agdir own" />
					}
					<span [innerText]="option.label"></span>
				</div>
			</ng-template>
			<ng-template #labelRenderer let-labels="labels" let-selectedOptions="selectedOptions">
				@for (selectedOption of selectedOptions; track $index) {
					<span>{{ selectedOption.name }}</span>
					@if (!$last) {
						/
					}
				}
			</ng-template>
		</nz-form-item>
	`,
	styles: [
		`
			::ng-deep ul.ant-cascader-menu {
				height: 100%;
				min-height: 180px;
				max-height: 300px;
			}
		`,
	],
	imports: [
		NzSelectModule,
		ReactiveFormsModule,
		NzColDirective,
		NzFormItemComponent,
		NzFormLabelComponent,
		NzRowDirective,
		TranslocoPipe,
		FormsModule,
		NzFormControlComponent,
		AddClassifierModalComponent,
		NzCascaderComponent,
		NgOptimizedImage,
		SpinnerComponent,
		AgdirIconComponent,
	],
})
export class ClassifierSelectComponent {
	label = input('');
	placeholder = input('');
	horizontal = input<'true' | 'false' | boolean>(true);
	showAddNew = input<'true' | 'false' | boolean>(false);
	size = input<'default' | 'large' | 'small'>('default');
	description = input('');
	knownClassifierId = input.required<WellKnownClassifierId>();

	classifierChange = output<Classifier>();
	clear = output<void>();
	cascader = viewChild.required(NzCascaderComponent);
	classifier = input.required<Classifier | null>();
	disabled = input(false);
	selectedValue = computed(() => {
		return this.classifier() && this.classifiers()?.length ? findCascaderValuePath(this.classifiers(), this.classifier()!) : [];
	});
	newClassifierForParent = signal<Classifier | null>(null);
	private readonly classifierService = inject(ClassifierService);

	classifiers = derivedAsync<NzCascaderOption[]>(async () => {
		const shouldReloadOnParentChange = this.newClassifierForParent();
		const classifier = await this.classifierService.getClassifierByIdAsync(this.knownClassifierId());
		return this.makeNzCascaderOptions(classifier);
	});
	private readonly translate = inject(I18nService);

	async pickedClassifier(cascaderPath: NzCascaderOption[]) {
		const pickedOption = cascaderPath.at(-1);
		if (pickedOption?.value === null) {
			this.newClassifierForParent.set(pickedOption['model'] as Classifier);
			return;
		}
		if (pickedOption) {
			const classifier = pickedOption['model'] as Classifier;
			this.classifierChange.emit(classifier);
			if (classifier.isLeaf()) {
				this.cascader()?.closeMenu();
			}
		} else {
			this.clear.emit();
		}
	}

	addedClassifier($event: Classifier) {
		this.newClassifierForParent.set(null);
		this.classifierChange.emit($event);
		this.cascader()?.closeMenu();
	}

	private makeNzCascaderOptions(parent: Classifier): NzCascaderOption[] {
		const options = parent.children?.map((c) => this.makeNzCascaderOption(c));
		if (options?.length && this.showAddNew()) {
			options.push(this.makeAddNewOption(parent));
		}
		return options || [];
	}

	private makeNzCascaderOption(classifier: Classifier): NzCascaderOption {
		return {
			label: classifier.name,
			value: classifier,
			children: this.makeNzCascaderOptions(classifier),
			isLeaf: classifier.isLeaf(),
			model: classifier,
		};
	}

	private makeAddNewOption(parent: Classifier): NzCascaderOption {
		return {
			label: this.translate.translate('Add new'),
			value: null,
			isLeaf: true,
			model: parent,
		};
	}
}
